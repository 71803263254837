<!-- =========================================================================================
  File Name: AddNewDataSidebar.vue
  norm: Add New Data - Sidebar component
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->


<template>
  <vs-sidebar click-not-close position-right parent="body" default-index="1" color="primary" class="add-new-data-sidebar items-no-padding" spacer v-model="isSidebarActiveLocal">
    <div class="mt-6 flex items-center justify-between px-6">
      <h4>{{ Object.entries(this.data).length === 0 ? $t("AJOUTER_UN") : $t("MODIFICATION_DU") }} {{$t("EQUIPEMENTTYPE")}}  </h4>
      <feather-icon icon="XIcon" @click.stop="isSidebarActiveLocal = false" class="cursor-pointer"></feather-icon>
    </div>
    <vs-divider class="mb-0"></vs-divider>
    <component :is="scrollbarTag" class="scroll-area--data-list-add-new" :settings="settings" :key="$vs.rtl">
      <div class="p-6">
          <p class="pt-4">{{ $t("equipmentType") }}<b style="color: #ff6141" >*</b> </p>
        <vs-input
          v-validate="'required'"
          data-vv-validate-on="blur"
          name="equipmentType"
          v-model="name"
          class="w-full" />
        <span class="text-danger text-sm" v-show="errors.has('equipmentType')">{{$t("equipmentType_error")}}</span>
         
        <p class="pt-4">{{ $t("norm") }}<b style="color: #ff6141" >*</b> </p>
        <vs-input
          v-validate="'required'"
          data-vv-validate-on="blur"
          name="norm"
          v-model="norm"
          class="w-full" />
        <span class="text-danger text-sm" v-show="errors.has('norm')">{{$t("norm_error")}}</span>

       <!--  <p class="pt-4" >{{ $t("company") }}<b style="color: #ff6141">*</b></p>
        <v-select
            v-validate="'required'"
            name="company"
            :reduce="rep => rep.id"
            class="w-full" label="name"
            v-model="company"
            :options="companies">
          </v-select>
          <span class="text-danger text-sm" v-show="errors.has('company')">{{ $t("company_error") }}</span>
 -->

        <div class="p-6"> 
              <!-- Product Image -->
      <template v-if="dataImg">

              <!-- Image Container -->
              <div class="img-container w-64 mx-auto flex items-center justify-center">
              <img :src="dataImg" alt="img" class="responsive">
              </div>

              <!-- Image upload Buttons -->
              <div class="modify-img flex justify-between mt-5">
              <input type="file" class="hidden" ref="updateImgInput" @change="updateCurrImg" accept="image/*">
              <vs-button class="mr-4" type="flat" @click="$refs.updateImgInput.click()">{{ $t("changer") }}</vs-button>
              <vs-button type="flat" color="#999" @click="dataImg = null">{{ $t("effacer") }}</vs-button>
              </div>

      </template>

      <div class="upload-img mt-5" v-if="!dataImg">
        <input type="file" class="hidden" ref="uploadImgInput" @change="updateCurrImg" accept="image/*">
        <vs-button v-if="!updatechek" @click="$refs.uploadImgInput.click()">{{ $t("ajouter_Image") }}</vs-button>
        <vs-button v-if="updatechek" @click="$refs.uploadImgInput.click()">{{ $t("modifier_Image") }}</vs-button>
      </div>
  </div>
      </div>
    </component>

    <div class="flex flex-wrap items-center p-6" slot="footer">
      <vs-button class="mr-6" @click="category_validate">{{$t("Soumettre")}}</vs-button>
      <vs-button type="border" color="danger" @click="isSidebarActiveLocal = false">{{$t("Annuler")}}</vs-button>
    </div>
  </vs-sidebar>
</template>

<script>

import VuePerfectScrollbar from 'vue-perfect-scrollbar'

export default {
  props: {
    isSidebarActive: {
      type: Boolean,
      required: true
    },
    data: {
      type: Object,
      default: () => {}
    }
  },
  components: {
    VuePerfectScrollbar
  },
  data () {
    return {
      //company:'',
      companies:[],
      name: '',
      norm: '',
      dataImg:null,
      dataImg1:null,
      id:'',
      updatechek:false,
      input:'',
      settings: { // perfectscrollbar settings
        maxScrollbarLength: 60,
        wheelSpeed: .60
      }
    }
  },
  watch: {
    isSidebarActive (val) {
      if (!val) return
      if (Object.entries(this.data).length === 0) {
        this.initValues()
        this.$validator.reset()
        this.updatechek = false
      } else {
        this.initValues()
        this.$validator.reset()
        this.updatechek = true
        //this.company = this.data.company.id
        this.id = this.data.id
        this.norm = this.data.norm
        this.name = this.data.name
        this.dataImg = null
        this.dataImg1 = null
      }
    }
  },

  computed: {
    isSidebarActiveLocal: {
      get () {
        return this.isSidebarActive
      },
      set (val) {
        if (!val) {
          this.$emit('closeSidebar')
        // this.$validator.reset()
        // this.initValues()
        }
      }
    },
    scrollbarTag () { return this.$store.getters.scrollbarTag }
  },
  methods: {
    updateCurrImg (input) {
      if (input.target.files && input.target.files[0]) {
        const reader = new FileReader()
        reader.onload = e => {
          this.dataImg = e.target.result
          this.dataImg1 = this.$refs.uploadImgInput.files[0]
        }
        reader.readAsDataURL(input.target.files[0])
      }
    },

    initValues () {
      //this.company = ''
      this.id = ''
      this.name = ''
      this.norm = ''
      this.dataImg = null
      this.dataImg1 = null
    },

    category_validate () {
      this.$validator.validateAll().then(result => {
        if (result) {
          this.submitData()
        }
      })
    },

    async submitData () {
      if (this.updatechek === false) {
        this.input = {
          norm : this.norm,
          name : this.name
        }
      }

      if (this.updatechek === true) {
        this.input = {
          id : this.id,
          norm : this.norm,
          name : this.name
        }
      }

      const formdata = new FormData()
      if (this.dataImg1 !== null) {
        formdata.append('picture', this.dataImg1,  this.dataImg1.name)
      }
      const keys = ['norm', 'name']
      const length = keys.length
      for (let j = 0; j < length; j++) {
        const item = keys[j]
        formdata.append(item, this.input[item])
      } 
      this.$vs.loading()
   
      let url = 'equipmentTypes/'
      let methods = 'post'
      const message = {
        error: this.$t('save_error'),
        success: this.$t('equipmentType_save')
      }
      if (this.updatechek === true) {
        if (this.input.id) {
          url += `${this.input.id}/`
          methods = 'put'
          message.success = this.$t('equipmentType_update')
        }
      }
   

      this.$http[methods](url, formdata)
        .then((response) => {
          window.equipmentTypes.getAllequipmentTypes()
          window.getPrendTaCom.success(message.success, response)
          this.$emit('closeSidebar')
          this.initValues()
        })
        .catch((error) => {

          const clefs = Object.keys(error.response.data)
          for (let i = 0; i < clefs.length; i++) {
            const item = clefs[i]
            let libelle = ''
            if (item === 'name') {
              libelle = this.$t('equipmentType')
            }
            if (item === 'norm') {
              libelle = this.$t('norm')
            }
            if (item === 'picture') {
              libelle = this.$t('picture')
            }
            for (let j = 0; j < error.response.data[item].length; j++) {
              window.getPrendTaCom.error(`${libelle} :  ${error.response.data[item][j]}`)
            }
          }

          window.getPrendTaCom.error(message.error)
        })
    }

  }
}
</script>

<style lang="scss" scoped>
.add-new-data-sidebar {
  ::v-deep .vs-sidebar--background {
    z-index: 52010;
  }

  ::v-deep .vs-sidebar {
    z-index: 52010;
    width: 400px;
    max-width: 90vw;

    .img-upload {
      margin-top: 2rem;

      .con-img-upload {
        padding: 0;
      }

      .con-upload {
        width: 100%;
        margin: 0;
      }
    }
  }
}

.scroll-area--data-list-add-new {
  // height: calc(var(--vh, 1vh) * 100 - 4.3rem);
  height: calc(var(--vh, 1vh) * 100 - 16px - 45px - 82px);

  &:not(.ps) {
    overflow-y: auto;
  }
}
</style>
